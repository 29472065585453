<template>
  <div class="w-full">
    <vs-button @click="updateNotifications()" class="ml-auto mb-5" icon-pack="feather" icon="icon-book-open">Mark as read</vs-button>
    <vs-table
      class="px-12"
      multiple
      v-model="selected"
      :data="notifications"
    >
      <template slot-scope="{ data }">
        <vs-tr :class="data[indextr].status" :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <td>
            <div @click="openNotification(data[indextr])">
              <div class="flex sm:w-full xs:w-2/5">
                <small class="mr-2">{{ 24 > data[indextr].time ? `${Math.floor(data[indextr].time)} h` : `${Math.floor(data[indextr].time / 24)} d`}}</small>
                <h4 class="mb-2 font-large text-semibold notification-title">{{ data[indextr].subject }}</h4>
                <small v-if="!isExpired(data[indextr].created)" class="text-red font-normal ml-1">{{data[indextr].link !== null && data[indextr].link.includes('s3.amazonaws.com') ? $t('Expired') : ''}}</small>
              </div>
              <div class="sm:w-full xs:w-2/5">
                <span class="mb-1">{{ data[indextr].excerpt }}</span><br>
                <small>{{ data[indextr].body }}</small>
              </div>
            </div>
          </td>
        </vs-tr>
      </template>
    </vs-table>
    <scroll-loader :loader-method="loadNotifications" :loader-disable="disable">
      <div>Loading...</div>
    </scroll-loader>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import AXIOS               from '../../http/axios/index.js'
import common              from '@/assets/utils/common'

export default {
  components: {
    VuePerfectScrollbar
  },
  data () {
    return {
      disable: false,
      selected: [],
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      },
      notifications: [],
      offset: '',
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      releaseVersion: process.env.VUE_APP_RELEASE_VERSION
    }
  },
  computed: {
    scrollbarTag () { return this.$store.getters.scrollbarTag }
  },
  methods: {
    isExpired (date) {
      const expiredDate = new Date(date)
      expiredDate.setHours(expiredDate.getHours() + 1)
      return expiredDate > new Date()
    },
    loadNotifications () {
      AXIOS.get(`${process.env.VUE_APP_API_URL}/api/v2/buzzer/in-app/?last_evaluated_key=${this.offset}&limit=${this.maximumItems}`, {headers: this.headers}, {
      })
        .then((response) => {
          this.notifications = [...this.notifications, ...response.data.results]
          this.notifications.forEach(element => {
            element.time = (new Date() - new Date(element.created)) / (1000 * 60 * 60) 
          })
          this.disable = response.data.next === null
          this.offset = response.data.next
          this.$vs.loading.close()
        })
        .catch((error) => {
          common.notifyResponseError(this, error)
          this.$vs.loading.close()
        })
    },
    openNotification (notification) {
      this.$vs.loading()
      if (this.$store.state.AppActiveUser.userRole === 'merchant' && this.releaseVersion !== 'staging') {
        const userMerchant = JSON.parse(localStorage.getItem('userMerchant'))
        const user = JSON.parse(localStorage.getItem('merchant'))
        if (notification.subject.includes('New Bank Deposit')) {
          this.$mixpanel.track('INAPP_BANK', {
            merchant_name: user.name,
            merchant_email: userMerchant.email,
            source: /(android)/i.test(navigator.userAgent)
          })
        }
        switch (notification.subject) {
        case 'Orders Upload Completed with Errors':
          this.$mixpanel.track('INAPP_UPLOAD_ERROR', {
            merchant_name: user.name,
            merchant_email: userMerchant.email,
            source: /(android)/i.test(navigator.userAgent)
          })
          break
        case 'Orders Upload Successfully Completed':
          this.$mixpanel.track('INAPP_UPLOAD_SUCCESS', {
            merchant_name: user.name,
            merchant_email: userMerchant.email,
            source: /(android)/i.test(navigator.userAgent)
          })
          break
        case 'Your export is complete!':
          this.$mixpanel.track('INAPP_EXPORT', {
            merchant_name: user.name,
            merchant_email: userMerchant.email,
            source: /(android)/i.test(navigator.userAgent)
          })
          break
        case 'A new statement is available':
          this.$mixpanel.track('INAPP_STATEMENT', {
            merchant_name: user.name,
            merchant_email: userMerchant.email,
            source: /(android)/i.test(navigator.userAgent)
          })
          break 
        default:
          break
        }
      }
      this.updateNotifications(notification)
    },
    updateNotifications (notification) {
      this.$vs.loading()
      const notifications = []
      if (notification && notification.status === 'unread') {
        notifications.push({
          created: notification.created,
          status: 'read'
        })
      } else {
        this.selected.forEach(element => {
          if (element.status === 'unread') {
            notifications.push({
              created: element.created,
              status: 'read'
            })
          }
        })
      }
      AXIOS.patch(`${process.env.VUE_APP_API_URL}/api/v2/buzzer/in-app/`, notifications, {headers: this.headers}, {
      })
        .then(() => {
          this.selected.forEach(item => {
            item.status = 'read'
          })
          localStorage.setItem('notifications', localStorage.getItem('notifications') - notifications.length)
          this.$vs.loading.close()
          if (notification.link) {
            this.redirctRouter(notification)
          }
        })
        .catch((error) => {
          common.notifyResponseError(this, error)
          this.$vs.loading.close()
        })
    },
    redirctRouter (notification) {
      if (notification.link !== null && (this.isExpired(notification.created) || !notification.link.includes('s3.amazonaws.com'))) {
        if (notification.link.startsWith('https') || notification.link.startsWith('http')) {
          window.open(notification.link, '_self')
        } else {
          this.$router.push({
            path: `/${this.$i18n.locale}${notification.link}`
          }).catch(() => {})
        }
      }
    },
    viewNotifications () {
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-notifications`
      }).catch(() => {})
    }
  }
}
</script>

<style lang="scss">

.vs-con-table td.td-check {
  width: 20px !important;
  padding-left: 25px !important;
  padding-right: 15px !important;
}
.unread {
  background: #dcdfdf;
  border-left: 20px#1c5bfe solid;
  font-weight: 600;
}
</style>